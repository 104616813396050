const updateMediaGrid = (productMedia, productData) => {
    console.log(productMedia.length);

    // Loading media grid elements
    const imageElements = document.querySelectorAll('.fusion-pdp__media-item');

    // Wrapper of the entire media grid
    const mediaContainer = document.getElementById('fusionMediaInnerWrapper');

    // Thumbnail elements
    const thumbnailElements = document.querySelectorAll('.fusion-pdp__media-thumbnail');
    const thumbnailContainer = document.querySelector('.fusion-pdp__media-thumbnails-container');

    const desktopMediaElements = document.querySelectorAll('.fusion-pdp__media-item-desktop');
    const desktopMediaContainer = document.querySelector('.fusion-pdp__media-item-desktop-container');
    productMedia.forEach((img, i) => {
        // Add or update media elements and containers
        if (imageElements[i] === undefined) {
            // Create a new media item if not enough exist
            mediaContainer.insertAdjacentHTML('beforeend', `
                <div id="fusionMediaItem">
                    <div class="fusion-pdp__media-item media pdp-media-ratio">
                        <img class="lazyload" loading="lazy" height="1400" width="1050" data-src="${img.src}" src="${img.src}">
                    </div>
                </div>
            `);
        } 
        else {
            // Update existing media item
            imageElements[i].innerHTML = `
                ${i === 0 && productData?.product?.model_info ? `
                    <div class="pdp__model-info"><span>${productData?.product?.model_info}</span></div>` : ''}
                <img class="lazyload" loading="lazy" height="1400" width="1050" data-src="${img.src}" src="${img.src}">
            `;
        }

        // Add or update thumbnail elements and containers
        if (thumbnailElements[i] === undefined) {
            // Create a new thumbnail if not enough exist
            thumbnailContainer.insertAdjacentHTML('beforeend', `
                <div @click="setActiveSlide(${i})" class="fusion-pdp__media-thumbnail hs-carousel-pagination-item w-fit relative shrink-0 rounded-md sm:rounded-lg overflow-hidden cursor-pointer border-2 shadow-sm max-w-[50px]" :class="activeSlide === ${i} ? 'border-[#1f1f22]' : ''">
                    <img class="lazyload pointer-events-none bg-gray-100 w-fit size-full object-contain rounded-md sm:rounded-lg" src="${img.src}" alt="${productData?.product?.title} Image ${i + 1}">
                </div>
            `);
        } else {
            // Update existing thumbnail
            thumbnailElements[i].innerHTML = `
                <img class="lazyload pointer-events-none bg-gray-100 w-fit size-full object-contain rounded-md sm:rounded-lg" src="${img.src}" alt="${productData?.product?.title} Image ${i + 1}">
            `;
        }

        if (desktopMediaElements[i] === undefined) {
            // Create a new desktop media item if not enough exist
            desktopMediaContainer.insertAdjacentHTML('beforeend', `
            <div x-show="activeSlide === ${i}" class="fusion-pdp__media-item-desktop bg-gray-100 hs-carousel-slide rounded-lg shadow-sm border border-gray-100">
                <img class="lazyload pointer-events-none size-full object-contain rounded-lg" src="${img.src}" alt="${productData?.product?.title} Image ${i + 1}">
            </div>
            `);
        } else {
            // Update existing desktop media item
            desktopMediaElements[i].innerHTML = `
                <img class="lazyload" loading="lazy" height="1400" width="1050" data-src="${img.src}" src="${img.src}">
            `;
        }
    });

    // Remove extra media elements left over from previous products
    imageElements.forEach((elm, i) => {
        if (i >= productMedia.length) {
            if (elm.closest) {
                elm.closest('#fusionMediaItem').remove(); // Remove extra media elements
            }
        }
    });

    desktopMediaElements.forEach((elm, i) => {
        if (i >= productMedia.length) {
            elm.remove(); // Remove extra thumbnails
        }
    });

    // Remove extra thumbnail elements left over from previous products
    thumbnailElements.forEach((elm, i) => {
        if (i >= productMedia.length) {
            elm.remove(); // Remove extra thumbnails
        }
    });

    if (window.innerWidth <= 900 && window.fusionDataLayer.activeSlider) {
        window.fusionElements.pdpMobileSlider.update();
    }


    // window.resetActiveSlide(productMedia.length);
};

export default updateMediaGrid;